import React, { useEffect } from "react";
/* React Icons */
import { FaDownload } from "react-icons/fa";

/* AOS (Animate On Scroll) */
import AOS from "aos";
import "aos/dist/aos.css";

/* Import your HorizontalBar component */
import HorizontalBar from "./Horizontal";

const TaxInfo = () => {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);

    // Initialize AOS
    AOS.init({
      duration: 800, // Animation duration
      easing: "ease-in-out", // Easing function
      once: true, // Whether animation should happen only once
    });
    AOS.refresh();
  }, []);

  // Get current and prior year dynamically
  const currentYear = new Date().getFullYear();
  const priorYear = currentYear - 1;

  const handleDownload = (url) => {
    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  // Data for tax documents
  const taxDocuments = [
    {
      label: "Current Year Form",
      year: `${currentYear}`,
      url: `https://firebasestorage.googleapis.com/v0/b/celestial-air-236406.appspot.com/o/Tax_information_Form_${currentYear}.docx?alt=media&token=5156e662-9f2e-4fea-838e-c628713d09d9`,
    },
    {
      label: "Prior Year Form",
      year: `${priorYear}`,
      url: `https://firebasestorage.googleapis.com/v0/b/celestial-air-236406.appspot.com/o/Tax_information_Form_${priorYear}.docx?alt=media&token=your_token_for_${priorYear}`,
    },
  ];

  return (
    <div className="bg-white py-28 px-5 lg:px-10 overflow-x-hidden">
      {/* Horizontal Bar Component */}
      <HorizontalBar />

      {/* Header Section */}
      <div
        className="text-center my-12"
        data-aos="fade-up"
        data-aos-duration="600"
      >
        <h1 className="text-3xl font-bold text-green-600 mb-4">
          Download Tax Information Documents
        </h1>
        <p className="text-gray-700 md:text-lg mb-8">
          Access the latest and previous year tax information forms below.
        </p>
      </div>

      {/* Tax Documents Buttons */}
      <div
        className="flex flex-col items-center space-y-6 mb-16"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="600"
      >
        {taxDocuments.map((item) => (
          <button
            key={item.year}
            onClick={() => handleDownload(item.url)}
            className="flex items-center bg-green-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 focus:outline-none transition duration-300 ease-in-out w-64 md:w-80"
            aria-label={`Download ${item.label} (${item.year})`}
          >
            <FaDownload className="w-5 h-5 mr-3" />
            <span className="text-lg font-semibold">{item.label}</span>
          </button>
        ))}
      </div>

      {/* Optional: Add additional sections or components below */}
    </div>
  );
};

export default TaxInfo;
