import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion"; // Install this via npm: `npm install framer-motion`

/* Images (import your own images here) */
import back from "./back.jpg";
import pic from "./pic.png";
import doc from "./doc.jpg";

/* ================== Home Component ================== */
export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-white text-black overflow-hidden">
      {/* Intro Section */}
      <Intro />

      {/* NEW: “Our Clients” Banner */}
      
      {/* Existing Content */}
      <Rcontent />
      <Content />
      <TaxEstimateForm />
      <Service />
    
      <OurClientsBanner />

      <Step />
      
      
  
      
      <Pricing /><Refer />
      <Lower />  
    </section>
  );
}

/* ================== NEW OurClientsBanner Component ================== */
/* ================== OurClientsBanner Component ================== */
const OurClientsBanner = () => {
  return (
    <section
      className=" text-green-600 py-16"
      data-aos="zoom-in"
      data-aos-duration="800"
    >
      <div className="container mx-auto px-4 py-24 text-center">
        <h2 className="text-4xl text-black md:text-5xl font-extrabold mb-4">
        Proudly Serving Leading  <span className="text-green-600">Global Corporations.</span> 
        </h2>
        <p className="max-w-2xl text-gray-700 mx-auto text-lg md:text-xl">
        Year-round, ATH Tax Services, Inc. is here to deliver convenient, trustworthy, and worry-free tax solutions to make your life easier.
        </p>

        {/* Logos Section */}
        <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-6 justify-items-center items-center">
          {/* Replace these logo src values with your actual image paths */}
          <img className="h-12 w-auto" src="https://crescenttaxfiling.com/wp-content/uploads/2024/08/microsoft-removebg-preview.png" alt="Company Logo 1" />
          <img className="h-12 w-auto" src="https://crescenttaxfiling.com/wp-content/uploads/2024/08/google-removebg-preview.png" alt="Company Logo 2" />
          <img className="h-12 w-auto" src="https://crescenttaxfiling.com/wp-content/uploads/2024/08/infosys-removebg-preview.png" alt="Company Logo 3" />
          <img className="h-12 w-auto" src="https://crescenttaxfiling.com/wp-content/uploads/2024/08/cognizant-removebg-preview.png" alt="Company Logo 4" />
          <img className="h-12 w-auto" src="https://signsalad.com/wp-content/uploads/2021/11/Screenshot-2021-11-03-at-12.14.11.png" alt="Company Logo 5" />
          <img className="h-12 w-auto" src="https://crescenttaxfiling.com/wp-content/uploads/2024/08/tcs-removebg-preview.png" alt="Company Logo 6" />
        </div>
      </div>
    </section>
  );
};
;

const Intro = () => {
  const backgroundImageUrl = `url(${back})`;

  return (
    <div className="relative overflow-hidden">
      {/* Background Section */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -1,
          opacity: 0.5,
        }}
      ></div>

      {/* Content Section */}
      <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
        <div className="mx-auto max-w-3xl text-center relative p-10">
          {/* Heading with Animation */}
          <motion.h1
            className="text-green-600 text-6xl font-extrabold sm:text-6xl"
            animate={{
              y: [0, -10, 0], // Move up and down
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "loop",
            }}
          >
            ATH Tax Services, Inc.
          </motion.h1>

          {/* Subheading */}
          <motion.p
            className="mx-auto mt-4 font-bold max-w-xl sm:text-2xl/relaxed"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Optimize your tax return for the highest possible refund with our
            expert tax filers.
          </motion.p>

          <motion.p
            className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1 }}
          >
            We’re ready to help you get your biggest refund.
          </motion.p>

          {/* Buttons */}
          <motion.div
            className="mt-8 flex flex-wrap justify-center gap-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 1.5, duration: 0.8 }}
          >
            <a
              className="block w-full rounded border border-green-600 bg-green-600 px-12 py-3 text-m font-medium text-gray-100 hover:bg-green-700 hover:text-gray-100 focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
              href="/login"
            >
              Start Filing
            </a>

            <a
              className="block w-full rounded border border-green-600 bg-white px-12 py-3 text-m font-medium text-green-600 hover:bg-gray-100 focus:outline-none focus:ring active:bg-gray-200 sm:w-auto"
              href="/appointment"
            >
              Make An Appointment
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const Refer = () => {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <div className="p-8 rounded-md shadow-xl sm:p-16 bg-gradient-to-r from-green-100 to-white">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-8 text-center lg:text-left">
            <h2 className="text-3xl md:text-4xl font-extrabold text-green-600 leading-tight">
              File Your Taxes and Earn $15 per Referral!
            </h2>
          </div>
          <div className="lg:w-1/2 text-gray-700">
            <p className="mb-4 mt-2 text-lg">
              Are you ready to supercharge your tax filing experience? At ATH
              Services, we help you maximize your tax refund and offer exciting
              rewards for sharing the joy of hassle-free tax filing with your
              friends!
            </p>
            <p className="mb-4 text-lg">
              Refer a friend to ATH Services and get $15 for every successful
              referral! Your friend gets excellent tax services, and you get
              rewarded – it's a win-win!
            </p>
            <a
              href="/register"
              aria-label="Get Started Now"
              className="inline-flex items-center px-6 py-3 text-lg font-semibold text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700"
            >
              Get Started Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const TaxEstimateForm = () => {
  return (
    <div className="container mx-auto" data-aos="fade-up"
    data-aos-duration="800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight  sm:text-4xl md:mx-auto">
          Services At <span className="text-green-600">Zero Cost !</span>
        </h2>
      </div>

      <table className="table-auto mx-auto mt-5 w-full sm:w-4/5">
        <thead>
          <tr>
            <th className=" text-2xl border px-4 py-2">Services</th>
            <th className=" text-2xl border px-4 py-2">Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">Tax Estimate</td>
            <td className="border px-4 py-2 flex items-center ">
              <div className="m-auto">
                <span className="line-through text-red-500 ">$90</span>
                <span> &nbsp; &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Form 1040 Income Below $30,800</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$110</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Tax Experts Unlimited Consultations
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$300</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Financial Advisory Service</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$1,500</span>
                <span> &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Tax Planning for TY 2022 & 2023
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$450</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Filed Tax Returns Assessment & Examination
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$110</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">ITIN Guidance</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$150</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              FBAR & FATCA E-Filing Per Account
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$50</span>
                <span> &nbsp; &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Tax Expert Support for Notices & Audits.
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$300</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">W4, W9 Guidance & Assistance</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$140</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
const Service = () => {

  return (
    <div className="bg-white py-40" data-aos="fade-up"
    data-aos-duration="800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight  sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <span className="relative ">File With Us And </span>
          </span>{" "}
          Avail Our <span className="text-green-600">Additional Benefits</span>
        </h2>
      </div>
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0"></div>
        <div className="relative grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              Accuracy Guarantee
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Audit & Assurance
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              365 days Customer Support
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Maximum Refunds by Our High Tax Knowledge
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              100% Data Security
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              Preserve your tax returns up to 9 tax years with data security
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Covered by data protection laws
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              100% Satisfaction Guarantee
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
/* ================== Step Section ================== */
const Step = () => {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      {/* Header Section */}
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div
          data-aos="fade"
          data-aos-offset="200"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight  sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <span className="relative">Get</span>
            </span>{" "}
            Your Tax Return In{" "}
            <span className="text-green-600">3 Easy Steps</span>
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            We will ask you to fill in some basic information and upload your
            W2. You will then receive your Free Tax Draft to review before you
            decide to pay and have your taxes filed with us.
          </p>
        </div>
      </div>

      {/* Steps Section */}
      <div className="grid gap-10 lg:grid-cols-4 sm:grid-cols-2">
        {[
          {
            title: "Fill In Basic Info",
            desc: "Quickly share your basic details with us.",
            icon: "📝",
          },
          {
            title: "Secure W2 Upload",
            desc: "Safely upload your W2 for a tailored tax draft.",
            icon: "📥",
          },
          {
            title: "Get Your Quote",
            desc: "Review your Free Tax Draft. Only pay when you’re ready.",
            icon: "💵",
          },
          {
            title: "Success",
            desc: "Thank you for choosing us for a stress-free tax filing experience.",
            icon: "✅",
            isLast: true,
          },
        ].map((step, idx) => (
          <div
            key={idx}
            data-aos="fade-up"
            data-aos-delay={idx * 150}
            className="group p-6 rounded-lg shadow-md border hover:shadow-xl transition-shadow bg-white text-center"
          >
            {/* Step Icon */}
            <div className="w-16 h-16 mx-auto mb-4 flex items-center justify-center bg-green-100 text-green-600 rounded-full text-4xl font-bold">
              {step.icon}
            </div>

            {/* Step Title */}
            <h3 className="text-2xl font-semibold text-gray-800 group-hover:text-green-600 transition-colors">
              {step.title}
            </h3>

            {/* Step Description */}
            <p className="mt-4 text-gray-600 group-hover:text-gray-800 transition-colors">
              {step.desc}
            </p>

            {/* Arrow/Check Icon */}
            {!step.isLast && (
              <svg
                className="w-6 h-6 mx-auto mt-6 text-green-600"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <line
                  fill="none"
                  strokeMiterlimit="10"
                  x1="2"
                  y1="12"
                  x2="22"
                  y2="12"
                />
                <polyline
                  fill="none"
                  strokeMiterlimit="10"
                  points="15,5 22,12 15,19"
                />
              </svg>
            )}
            {step.isLast && (
              <svg
                className="w-8 h-8 mx-auto mt-6 text-green-600"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <polyline
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points="6,12 10,16 18,8"
                />
              </svg>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


export const Pricing = () => {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      {/* Header Section */}
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <p className="inline-block px-3 py-1 mb-4 text-xs font-semibold tracking-wider text-green-600 uppercase rounded-full bg-green-100">
          Our Pricing
        </p>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-extrabold leading-tight tracking-tight sm:text-4xl md:mx-auto">
          How Do You Want To Get Started With{" "}
          <span className="text-green-600">Your Tax Pro?</span>
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Access legitimate tax returns immediately upon choosing our services,
          with no commitments required.
        </p>
      </div>

      {/* Pricing Cards */}
      <div className="grid gap-10 row-gap-5 sm:grid-cols-2 lg:grid-cols-3 sm:mx-auto">
        {[
          {
            title: "Federal Filing",
            price: "59",
            desc: "Free tax estimate.",
            animation: "flip-right",
          },
          {
            title: "State Filing",
            price: "30",
            desc: "Free tax estimate.",
            animation: "flip-down",
          },
          {
            title: "Business Filing",
            price: "250",
            desc: "Free tax expert consultation.",
            animation: "flip-left",
          },
        ].map((plan, idx) => (
          <div
            key={idx}
            data-aos={plan.animation}
            data-aos-duration="600"
            className={`p-6 transition-shadow duration-300 rounded-lg shadow-md hover:shadow-xl ${
              plan.title.includes("State")
                ? "bg-white border border-gray-200"
                : "bg-green-600 text-white"
            }`}
          >
            <div className="text-center">
              <h3 className="text-2xl font-bold mb-4">{plan.title}</h3>
              <div className="text-lg font-medium mb-2">Starting at</div>
              <div className="text-4xl font-bold mb-4">${plan.price}</div>
              <p className="text-sm">{plan.desc}</p>
            </div>
            <div className="mt-6 text-center">
              <a
                href="/"
                className={`inline-block px-6 py-2 text-sm font-medium leading-5 transition-colors duration-200 transform rounded-lg ${
                  plan.title.includes("State")
                    ? "bg-green-600 text-white hover:bg-green-700"
                    : "bg-white text-green-600 hover:bg-gray-100"
                }`}
              >
                Get Started
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export const Lower = () => {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <div className="max-w-screen-sm sm:text-center sm:mx-auto">
        <div className="flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-green-100 sm:mx-auto">
          <svg
            className="w-12 h-12 text-green-600"
            stroke="currentColor"
            viewBox="0 0 52 52"
          >
            <polygon
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
              points="29 13 14 29 25 29 23 39 38 23 27 23"
            />
          </svg>
        </div>
        <h2 className="mb-4 font-sans text-3xl font-extrabold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
          100% Guarantee
        </h2>
        <p className="text-base text-gray-700 md:text-lg sm:px-4">
          Get Your Max Refund with 100% Accuracy Guaranteed.
        </p>
        
        <hr className="w-full my-8 border-gray-300" />
      </div>
    </div>
  );
};


/* ================== Content Sections ================== */
export const Content = () => {
  return (
    <div className="px-4 py-16 mx-auto md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2 items-center">
        {/* Text Section */}
        <div data-aos="fade-right" data-aos-duration="600">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <h2 className="text-4xl font-extrabold leading-tight text-gray-800 sm:text-5xl">
              Over{" "}
              <span className="text-green-600">Half A Million</span> Clients
              Have Made ATH Tax Services, Inc. Their Top Choice
            </h2>
            <p className="mt-6 text-lg text-gray-600">
              Upload your W2s and fill in some basic info. We’ll send you a free
              tax draft in 24 hours to review — only pay when you’re ready to
              file!
            </p>
          
          </div>
        </div>

        {/* Image Section */}
        <div
          className="flex items-center justify-center lg:pl-8"
          data-aos="fade-left"
          data-aos-duration="600"
        >
          <img
            className="h-96 w-auto rounded-xl shadow-lg"
            src={doc}
            alt="Document Preview"
          />
        </div>
      </div>
    </div>
  );
};
export const Rcontent = () => {
  return (
    <div className="px-4 py-16 mx-auto md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2 items-center">
        {/* Image Section */}
        <div
          className="flex items-center justify-center lg:pl-8"
          data-aos="fade-right"
          data-aos-duration="600"
        >
          <img
            className="h-96 w-auto rounded-xl shadow-lg"
            src={pic}
            alt="Advance Refund"
          />
        </div>

        {/* Text Section */}
        <div data-aos="fade-left" data-aos-duration="600">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <p className="text-lg text-red-500 font-medium uppercase">
              Advance Refund
            </p>
            <h2 className="mt-4 text-4xl font-extrabold leading-tight text-gray-800 sm:text-5xl">
              Get up to{" "}
              <span className="text-green-600">$4,000</span> Refund instantly
            </h2>
            <p className="mt-6 text-lg text-gray-600">
              You could get a Refund Advance loan up to $4,000 within one day
              from IRS acceptance. No interest, no loan fees, and no impact to
              your credit score.
            </p>
          
          </div>
        </div>
      </div>
    </div>
  );
};
