import React, { useEffect } from "react";
/* React Icons */
import {
  FaMoneyBillWave,
  FaShieldAlt,
  FaCheckCircle,
  FaSmileBeam,
} from "react-icons/fa";

/* AOS */
import AOS from "aos";
import "aos/dist/aos.css";

const Money = () => {
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);

    // Initialize AOS
    AOS.init();
    AOS.refresh();
  }, []);

  /* 
     Data for each guarantee card to keep the code DRY (Don't Repeat Yourself).
     You can easily update titles, icons, text, or AOS animations here.
  */
  const guarantees = [
    {
      icon: <FaMoneyBillWave className="text-green-600 w-8 h-8" />,
      title: "Maximum Refund Guarantee",
      description: `
        To be eligible for the ATH Maximum Refund Guarantee, the refund
        claim must be submitted within the same calendar year in which the
        return was prepared. Additionally, the larger refund or reduced tax
        liability must not result from incomplete, inaccurate, or
        inconsistent information provided by you, decisions made by you,
        your choice to forgo claiming a deduction or credit, conflicting tax
        laws, or alterations in tax laws post January 1, 2023.
      `,
      aosAnimation: "fade-up",
    },
    {
      icon: <FaShieldAlt className="text-green-600 w-8 h-8" />,
      title: "No Surprise Guarantee",
      description: `
        If we fail in delivering any of the key advantages outlined in our
        “No Surprise Guarantee” (which comprises Upfront Transparent
        Pricing, Transparent Process, Free Audit Assistance, and Free
        Mid-Year Care Check-In), you will be eligible for a 50% discount on
        next year’s tax preparation fee when filing with ATH Tax.
      `,
      aosAnimation: "fade-up",
    },
    {
      icon: <FaCheckCircle className="text-green-600 w-8 h-8" />,
      title: "100% Accuracy Guarantee",
      description: `
        If ATH Tax makes an error on your return, we will reimburse 100% for
        any resulting penalties and interest, up to $10,000 in penalty &
        interest charges. The ATH 100% Accuracy Guarantee applies if errors
        are not a result of incomplete or inaccurate information provided by
        you, your unsupported or incorrect positions, your decision not to
        claim a deduction or credit, conflicting tax laws, or changes in tax
        laws after January 1, 2024.
      `,
      aosAnimation: "fade-up",
    },
    {
      icon: <FaSmileBeam className="text-green-600 w-8 h-8" />,
      title: "100% Satisfaction Guarantee",
      description: `
        We’re so certain you’ll be satisfied with the tax preparation
        services at our offices; you only pay us if you accept your return.
        By authorizing ATH to e-file your tax return or by taking the
        completed return to file, you are accepting the return and are
        obligated to pay all fees when due.
      `,
      aosAnimation: "fade-up",
    },
  ];

  return (
    <div className="bg-white py-28">
      {/* Header Section */}
      <div className="px-4 mx-auto max-w-screen-xl md:px-24 lg:px-8">
        <div className="max-w-screen-sm text-center mx-auto">
          <div className="inline-block mb-5 rounded-full">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-white shadow-md mx-auto">
              {/* Primary Icon or Logo for the Heading */}
              <FaMoneyBillWave className="text-green-600 w-8 h-8" />
            </div>
          </div>
          <h2 className="mb-4 font-sans text-4xl font-bold tracking-tight text-green-600 sm:text-5xl sm:leading-none">
            Money Back Guarantee
          </h2>
          <hr className="w-full my-8 border-gray-300" />
        </div>
      </div>

      {/* Guarantee Cards Section */}
      <div className="grid gap-8 row-gap-10 lg:grid-cols-2 p-5 max-w-screen-xl mx-auto">
        {guarantees.map((guarantee, index) => (
          <div
            key={index}
            className="max-w-md sm:mx-auto sm:text-center bg-white rounded-lg p-8 shadow hover:shadow-lg transition-transform duration-300 hover:scale-105"
            data-aos={guarantee.aosAnimation}
            data-aos-delay={index * 100}     // Stagger the animations
            data-aos-duration="600"
          >
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50 mx-auto">
              {guarantee.icon}
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5 text-green-600">
              {guarantee.title}
            </h6>
            <p className="text-sm text-gray-700">{guarantee.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Money;
