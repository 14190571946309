// Footer.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
 FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const privacyPolicyContent = `
# Privacy Policy

At ATH Tax Services, Inc., we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By using our services, you agree to the practices described in this policy.

## 1. Information Collection and Use
We collect both personal and non-personal data to enhance your experience and provide you with our services. The personal information we collect may include, but is not limited to, your name, email address, contact details, and payment information. This data is used to:
- Provide and improve our services,
- Communicate with you regarding account-related matters,
- Process transactions and fulfill orders, and
- Respond to inquiries and support requests.

We do not sell or rent your personal information to third parties.

## 2. Data Protection
We implement a variety of security measures to protect your personal data, including encryption, firewalls, and secure server infrastructure. We use industry-standard practices to safeguard the confidentiality and integrity of your personal data and restrict access to authorized personnel only.

## 3. Third-Party Privacy Policies
We may share your personal information with third-party service providers to assist with business operations such as payment processing, data hosting, and email communications. These third parties are obligated to maintain the confidentiality of your data and adhere to their own privacy policies. We are not responsible for the privacy practices or content of external websites linked to from our platform.

## 4. Children’s Privacy
Our services are not intended for use by children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have inadvertently collected such data, we will take immediate steps to delete it.

## 5. Online Privacy Policy
This Privacy Policy applies exclusively to information collected through our website and online services. It does not extend to any offline activities or third-party websites that may be linked to our platform. We encourage you to review the privacy policies of any third-party websites you visit.

## 6. Consent
By using our services, you consent to the collection and use of your personal data in accordance with this Privacy Policy. If you do not agree with any part of this policy, please refrain from using our services. You may withdraw your consent at any time by contacting us directly.

## 7. General Data Protection Regulation (GDPR)
For users in the European Union, this Privacy Policy complies with the General Data Protection Regulation (GDPR). Under GDPR, you have the right to request access to, rectification of, or deletion of your personal data. You may also exercise your right to restrict the processing of your data or object to certain processing activities. To exercise these rights, please contact us using the details provided below.

## 8. Log Files
We collect log data, including IP addresses, browser types, access times, and referring URLs, for system maintenance, troubleshooting, and improving website functionality. This information is stored in aggregate and is not linked to personally identifiable data.

## 9. Cookies and Web Beacons
We use cookies and web beacons to improve your user experience, track site usage, and personalize content. Cookies allow us to remember your preferences and activity on the site. You can manage cookie settings through your browser, but please note that disabling cookies may impact your ability to use certain features of our services.

## 10. Data Retention
We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including legal, regulatory, or contractual obligations. Once your data is no longer required, it will be securely deleted or anonymized.

## 11. International Data Transfers
If you are located outside of the United States, please be aware that your data may be transferred to and stored in the U.S. The U.S. has different data protection laws than your home country. By using our services, you consent to the transfer of your data to the United States.

## 12. Changes to this Privacy Policy
We may update this Privacy Policy periodically. When changes are made, we will revise the “Effective Date” at the top of the policy. Significant changes will be communicated to you through email or other appropriate means. We encourage you to review this Privacy Policy regularly to stay informed of any updates.

## 13. Contact Us
If you have any questions or concerns regarding this Privacy Policy, or if you wish to exercise your rights under GDPR, please contact us at:

**ATH Tax Services, Inc.**  
- +1 804 665 7826
- [taxsupport@athtax.com](mailto:taxsupport@athtax.com)
`;

const termsAndConditionsContent = `
# Terms & Conditions

## 1. Introduction
By engaging with our Services, you agree to these Terms. If you disagree with any part of these terms, please do not proceed with our services.

## 2. Scope of Services
- 2.1 We offer comprehensive taxation Services, including tax preparation, filing, planning, consultation, and tax advisory.  
- 2.2 Services will be provided as per the specific package or service agreement you select.  
- 2.3 Our services do not guarantee any specific outcome, including the amount of tax savings or refunds.

## 3. Client Responsibilities
- 3.1 You agree to provide accurate, complete, and up-to-date information regarding your financial situation and tax-related matters.  
- 3.2 If additional documentation or clarifications are needed, you will promptly provide it to ensure accurate service delivery.  
- 3.3 You are responsible for reviewing and approving all documentation before submission or filing.

## 4. Service Fees & Payment
- 4.1 Service fees are based on the complexity of the work involved, as detailed in your agreement. All fees will be communicated upfront.  
- 4.2 Payment terms will be outlined in the service agreement, and payments are due as specified.  
- 4.3 Fees do not include additional costs such as government taxes, filing fees, or any third-party charges.

## 5. Confidentiality & Data Protection
- 5.1 We are committed to protecting your personal and financial information. All details shared with us will be kept confidential, in line with our Privacy Policy.  
- 5.2 We will not disclose your personal or tax information unless required by law or with your consent.

## 6. Limitations of Liability
- 6.1 While we take every care in providing accurate advice and filing Services, we are not responsible for any penalties, fines, or legal consequences resulting from inaccurate or incomplete information provided by you.  
- 6.2 Our liability will be limited to the fees paid for the specific service rendered.

## 7. Dispute Resolution
- 7.1 In the event of any disagreement or dispute arising out of our Services, we encourage resolution through direct communication.  
- 7.2 If a resolution is not reached, disputes may be resolved through arbitration, as per the jurisdiction specified in the agreement.

## 9. Accuracy of Information
- 9.1 You acknowledge that the accuracy of tax filings depends on the accuracy and completeness of the information you provide.  
- 9.2 You are responsible for ensuring the correctness of your tax-related information and for reviewing all documents provided by us.

## 11. Governing Law
- 11.1 These Terms and Conditions are governed by the laws of [Your Jurisdiction]. Any disputes will be resolved under this jurisdiction.

## 12. Miscellaneous
- 12.1 If any part of these Terms is found to be unenforceable, the remainder will continue to be in effect.  
- 12.2 These Terms constitute the entire agreement between you and us with regard to the services provided.

## 13. Contact Us
If you have any questions or concerns regarding this Privacy Policy, or if you wish to exercise your rights under GDPR, please contact us at:

**ATH Tax Services, Inc.**  
- +1 804 665 7826
- [taxsupport@athtax.com](mailto:taxsupport@athtax.com)
`;

export default function Footer() {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  // Function to close modals when clicking outside the modal content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsPrivacyModalOpen(false);
      setIsTermsModalOpen(false);
    }
  };

  return (
    <footer className="bg-green-600 text-white">
      <div className="container mx-auto px-6 py-8">

        {/* Top Row: Brand + Contact Info */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          {/* Brand + Description */}
          <div className="mb-6 md:mb-0">
            <Link to="/" className="inline-flex items-center">
              {/* Logo with White Color */}
              <svg
  className="w-4 text-white"
  viewBox="0 0 24 24"
  strokeLinejoin="round"
  strokeWidth="2"
  strokeLinecap="round"
  strokeMiterlimit="10"
  stroke="currentColor"
  fill="none"
>
  <rect x="3" y="1" width="7" height="12" />
  <rect x="3" y="17" width="7" height="6" />
  <rect x="14" y="1" width="7" height="6" />
  <rect x="14" y="11" width="7" height="12" />
</svg>
              <span className="ml-2 text-xl font-bold">ATH Tax Services</span>
            </Link>
            <p className="mt-4 text-sm max-w-sm">
              Reliable and trusted taxation services for individuals and businesses.
            </p>
            <div className="mt-2">
              <span className="font-semibold">Email:</span>{" "}
              <a
                href="mailto:Taxsupport@athtax.com"
                className="hover:underline"
              >
                Taxsupport@athtax.com
              </a>
            </div>
            <div className="mt-1">
              <span className="font-semibold">Phone:</span>{" "}
              <a href="tel:+15714631136" className="hover:underline text-sm">
                +1 804 665 7826
              </a>
            </div>
          </div>

          {/* Services & Account Access */}
          <div className="mt-8 flex flex-col md:flex-row md:space-x-8">
            {/* Individual Services */}
            <div>
              <h2 className="font-semibold mb-3">Individual Services</h2>
              <ul className="space-y-1 text-sm">
                <li>
                  <Link to="/service" className="hover:underline">
                    Tax Planning Advisory Services
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    Tax Return Services
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    Tax Consultant & Tax Advisory
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    ITIN Processing
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    FBAR FATCA Processing
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    Extension Filing
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    Tax Preparation Services
                  </Link>
                </li>
              </ul>
            </div>

            {/* Business Services */}
            <div>
              <h2 className="font-semibold mb-3">Business Services</h2>
              <ul className="space-y-1 text-sm">
                <li>
                  <Link to="/service" className="hover:underline">
                    Business Incorporation Consulting Services
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    Accounting Book Keeping Services
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    Payroll Processing Services
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="hover:underline">
                    Tax Return Planning & Preparation
                  </Link>
                </li>
              </ul>
            </div>

            {/* Access Your Account */}
            <div>
              <h2 className="font-semibold mb-3">Access Your Account</h2>
              <ul className="space-y-1 text-sm">
                <li>
                  <Link to="/login" className="hover:underline">
                    Customer Portal Login
                  </Link>
                </li>
                <li>
                  <Link to="/register" className="hover:underline">
                    Free Sign-Up
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom Row: Address, Social Icons, Policy Links */}
        <div className="mt-8 border-t border-green-500 pt-4 text-sm flex flex-col md:flex-row justify-between items-start md:items-center">
          {/* Address & Hours */}
          <div className="mb-4 md:mb-0">
            <p className="font-semibold">Address:</p>
            <a
              href="https://www.google.com/maps"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline block"
            >
              11321 Grey Oaks Estates Way, Glen Allen, VA 23059
            </a>
            <p className="mt-2">
              Mon - Saturday (EST 10:30 AM to 6:30 PM)
            </p>
            <p className="mt-2">
              © {new Date().getFullYear()} ATH Tax Services. All rights reserved.
            </p>
          </div>

          {/* Policy Links & Social Icons */}
          <div className="flex flex-col md:flex-row md:items-center md:space-x-6 w-full md:w-auto">
            {/* Policy Links */}
            <ul className="flex space-x-4 mb-4 md:mb-0">
              <li>
                <button
                  onClick={() => setIsPrivacyModalOpen(true)}
                  className="hover:underline focus:outline-none"
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <button
                  onClick={() => setIsTermsModalOpen(true)}
                  className="hover:underline focus:outline-none"
                >
                  Terms & Conditions
                </button>
              </li>
            </ul>

            {/* Social Icons */}
            <div className="flex space-x-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300 flex items-center"
              >
                <FaFacebookF className="mr-1" />
                Facebook
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300 flex items-center"
              >
                <FaXTwitter className="mr-1" />
                Twitter
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300 flex items-center"
              >
                <FaLinkedinIn className="mr-1" />
                LinkedIn
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300 flex items-center"
              >
                <FaInstagram className="mr-1" />
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Policy Modal */}
      {isPrivacyModalOpen && (
        <div
          className=" fixed p-5 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleOverlayClick}
          aria-labelledby="privacy-policy-title"
          role="dialog"
          aria-modal="true"
        >
          <div className=" bg-white text-gray-800 rounded-lg max-w-3xl w-full max-h-full overflow-y-auto shadow-lg relative">
            {/* Modal Header */}
            <div className="bg-green-600 text-white sticky top-0 flex justify-between items-center p-4 rounded-t-lg">
              <h2 id="privacy-policy-title" className="text-2xl font-semibold">
                Privacy Policy
              </h2>
              <button
                className="text-white hover:text-gray-200 focus:outline-none"
                onClick={() => setIsPrivacyModalOpen(false)}
                aria-label="Close Privacy Policy Modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className=" p-6 space-y-4">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="prose max-w-none"
              >
                {privacyPolicyContent}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      )}

      {/* Terms & Conditions Modal */}
      {isTermsModalOpen && (
        <div
          className="fixed p-5 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleOverlayClick}
          aria-labelledby="terms-and-conditions-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="bg-white text-gray-800 rounded-lg max-w-3xl w-full max-h-full overflow-y-auto shadow-lg relative">
            {/* Modal Header */}
            <div className="bg-green-600 text-white sticky top-0 flex justify-between items-center p-4 rounded-t-lg">
              <h2 id="terms-and-conditions-title" className="text-2xl font-semibold">
                Terms & Conditions
              </h2>
              <button
                className="text-white hover:text-gray-200 focus:outline-none"
                onClick={() => setIsTermsModalOpen(false)}
                aria-label="Close Terms & Conditions Modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6 space-y-4">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="prose max-w-none"
              >
                {termsAndConditionsContent}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}
