// MyAccount.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HorizontalBar from "./Horizontal"; // Ensure this component exists
import { storage, db, auth } from "./firebase"; // Ensure firebase.js is correctly set up
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, getDoc, setDoc, collection, addDoc } from "firebase/firestore";
import { FaEdit } from "react-icons/fa"; // For the edit icon
import EditProfileModal from "./EditProfileModal"; // Ensure this component is created

const MyAccount = () => {
  // State to hold user data
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    referralEmail: "",
    email: "", // To display user's email
  });

  // State to handle file uploads
  const [files, setFiles] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedType, setSelectedType] = useState("individual");
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false); // Indicates if files are being uploaded
  const [error, setError] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Controls the visibility of the edit modal

  const navigate = useNavigate();

  // Fetch user data on component mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setError("User not authenticated");
        setLoading(false);
        navigate("/login"); // Redirect to login if not authenticated
        return;
      }

      try {
        const userDocRef = doc(db, "users", user.email);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData({ ...userDoc.data(), email: user.email });
        } else {
          setError("No user data found");
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError(`Failed to fetch user data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  // Handle file input changes
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    console.log("Selected Files:", selectedFiles);
    setFiles([...files, ...Array.from(selectedFiles)]);
  };

  // Handle form input changes (disabled fields in main form)
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle year selection
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  // Handle return type selection
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  // Handle form submission for uploading tax documents
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (
      !userData.firstName ||
      !userData.lastName ||
      !userData.phone ||
      !selectedYear ||
      files.length === 0
    ) {
      alert("Please fill in all required fields and upload at least one file.");
      return;
    }

    try {
      const user = auth.currentUser;

      if (!user) {
        alert("User not authenticated");
        return;
      }

      setUploading(true); // Start uploading

      const folderPath = `user-uploads/${user.email}`;

      // Upload all files to Firebase Storage
      const uploadedFiles = await Promise.all(
        files.map(async (file) => {
          const taggedFileName = `original-${Date.now()}-${file.name}`; // Ensure unique filenames
          const storageRefPath = `${folderPath}/${taggedFileName}`;
          const storageRefInstance = ref(storage, storageRefPath);
          await uploadBytes(storageRefInstance, file);
          const fileUrl = await getDownloadURL(storageRefInstance);

          const fileData = {
            name: taggedFileName,
            size: file.size,
            type: "original",
            uploadedAt: new Date().toISOString(),
            url: fileUrl,
            userEmail: user.email,
          };

          // Add file metadata to Firestore
          await addDoc(collection(db, "files"), fileData);

          return fileData;
        })
      );

      // Save submission data to Firestore
      await setDoc(doc(db, "submitted", user.email), {
        ...userData,
        selectedYear,
        selectedType,
        folderPath,
        files: uploadedFiles,
        submittedAt: new Date().toISOString(),
      });

      alert("Data submitted successfully!");
      navigate("/submit"); // Redirect after successful submission
    } catch (error) {
      console.error("Error uploading files and storing data: ", error);
      alert("Failed to submit data. Please try again.");
    } finally {
      setUploading(false); // End uploading
    }
  };

  // Show loading indicator while fetching data
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-xl text-gray-700">Loading...</div>
      </div>
    );
  }

  // Show error message if any
  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r  py-10 px-5">
      <div className="bg-white py-20 px-5 lg:px-10 overflow-x-hidden">
        <HorizontalBar />
        <div className="px-6 py-8">
          {/* Profile Section */}
          <div className="flex flex-col items-center mb-8">
            <h2 className="text-2xl font-semibold text-gray-800">
              {userData.firstName} {userData.lastName}
            </h2>
            <p className="text-gray-600">{userData.email}</p>
            <p className="text-gray-600">Phone: {userData.phone}</p>
            {userData.referralEmail && (
              <p className="text-gray-600">
                Referral: {userData.referralEmail}
              </p>
            )}
            <button
              onClick={() => setIsEditModalOpen(true)}
              className="mt-4 flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
            >
              <FaEdit className="mr-2" /> Edit Profile
            </button>
          </div>

          {/* Upload Tax Documents Form */}
          <h1 className="text-3xl font-bold text-center text-green-600 mb-6">
            Upload Tax Documents
          </h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Full Name Fields (Disabled) */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-gray-700 font-medium mb-2"
                >
                  First Name<span className="text-red-500">*</span>
                </label>
                <input
                  id="firstName"
                  type="text"
                  value={userData.firstName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-lg bg-white cursor-not-allowed"
                  required
                  disabled
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Last Name<span className="text-red-500">*</span>
                </label>
                <input
                  id="lastName"
                  type="text"
                  value={userData.lastName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-lg bg-white cursor-not-allowed"
                  required
                  disabled
                />
              </div>
            </div>

            {/* Contact Information (Disabled) */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Phone<span className="text-red-500">*</span>
                </label>
                <input
                  id="phone"
                  type="tel"
                  value={userData.phone}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-lg bg-white cursor-not-allowed"
                  required
                  disabled
                />
              </div>
              <div>
                <label
                  htmlFor="referralEmail"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Referral Email
                </label>
                <input
                  id="referralEmail"
                  type="email"
                  value={userData.referralEmail}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-lg bg-white cursor-not-allowed"
                  disabled
                />
              </div>
            </div>

            {/* Year Selection */}
            <div>
              <label
                htmlFor="year"
                className="block text-gray-700 font-medium mb-2"
              >
                Select Year<span className="text-red-500">*</span>
              </label>
              <select
                id="year"
                value={selectedYear}
                onChange={handleYearChange}
                className="w-full px-4 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-green-400"
                required
              >
                <option value="">Select Year</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>

            {/* Return Type */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Return Type<span className="text-red-500">*</span>
              </label>
              <div className="flex items-center space-x-6">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="individual"
                    checked={selectedType === "individual"}
                    onChange={handleTypeChange}
                    className="form-radio h-5 w-5 text-green-600"
                    required
                  />
                  <span className="ml-2 text-gray-700">Individual</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="business"
                    checked={selectedType === "business"}
                    onChange={handleTypeChange}
                    className="form-radio h-5 w-5 text-green-600"
                    required
                  />
                  <span className="ml-2 text-gray-700">Business</span>
                </label>
              </div>
            </div>

            {/* File Upload */}
            <div>
              <label
                htmlFor="file"
                className="block text-gray-700 font-medium mb-2"
              >
                Upload Tax Files<span className="text-red-500">*</span>
              </label>
              <input
                id="file"
                type="file"
                multiple
                onChange={handleFileChange}
                className="w-full px-4 py-2 border border-dashed rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-400"
                required
              />
              {files.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-gray-700 font-medium mb-2">
                    Selected Files:
                  </h3>
                  <ul className="list-disc list-inside">
                    {files.map((file, index) => (
                      <li key={index} className="text-gray-600">
                        {file.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                disabled={uploading}
                className={`w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ${
                  uploading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {uploading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Edit Profile Modal */}
      {isEditModalOpen && (
        <EditProfileModal
          userData={userData}
          setUserData={setUserData}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </div>
  );
};

export default MyAccount;
