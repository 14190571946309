import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
// 1. Import AOS
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceDetails = () => {
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);

  // 2. Initialize AOS on component mount
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Smooth scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const services = [
    {
      title: "Tax Planning & Advisory Services",
      shortDesc: "Comprehensive tax planning and advisory services",
      icon: "🎯",
      content: `
# Tax Planning & Advisory Services

Maximize your tax savings and ensure compliance with expert guidance tailored to your unique financial situation. Our tax planning and advisory services are designed to help individuals, families, and businesses navigate the complexities of tax laws, minimize liabilities, and optimize financial strategies.

---

## Our Tax Planning Services Include:

### 1. Personal Tax Planning:
- **Tax Return Optimization:** We help individuals structure their finances to reduce tax burdens through careful planning of income, deductions, and tax credits.
- **Retirement Planning:** Maximize tax benefits by organizing your retirement accounts, such as IRAs and 401(k)s, to align with your long-term financial goals.
- **Estate & Inheritance Tax Planning:** Protect your legacy with strategies to minimize estate and inheritance taxes, ensuring your wealth is passed on smoothly.
- **Tax Reduction Strategies:** Identify and implement strategies to reduce current and future tax liabilities, such as tax-efficient investments and charitable giving.

### 2. Business Tax Planning:
- **Corporate Tax Strategy:** Develop tax-efficient strategies for businesses, including deductions, credits, and entity structure to minimize corporate tax burdens.
- **Tax Compliance & Reporting:** Stay compliant with federal, state, and local tax regulations while maximizing available credits and deductions.
- **Succession & Exit Planning:** Ensure your business transitions smoothly with tax-efficient exit strategies, including sales, mergers, and ownership transfers.
- **IRS Audit Defence:** Protect your business against audits with proactive audit risk management and representation.

### 3. International Tax Advisory:
- **Cross-Border Tax Planning:** For individuals and businesses with international interests, we provide expert guidance on managing tax obligations across borders, including tax treaties and foreign tax credits.
- **Expatriate Tax Services:** Provide tailored tax solutions for expatriates, ensuring compliance with both U.S. tax laws and those of the foreign country.
- **Global Tax Optimization:** Develop strategies for multinational corporations to minimize global tax liabilities while ensuring adherence to international tax regulations.

### 4. Tax Law & Regulatory Updates:
- **Tax Law Changes:** Stay ahead of ever-changing tax laws, ensuring your tax strategy evolves with new regulations and opportunities.
- **Proactive Tax Strategy Adjustments:** We continuously monitor tax reforms and adjust your strategies to maintain optimal tax efficiency.

---

## Why Choose Our Tax Planning & Advisory Services?
- **Tailored Approach:** We take the time to understand your financial goals and provide customized solutions that align with your objectives.
- **Comprehensive Expertise:** From personal taxes to complex corporate tax matters, our team has the knowledge and experience to handle all aspects of tax planning.
- **Proactive & Strategic:** We don’t just react to tax situations—we help you plan ahead to avoid tax pitfalls and take advantage of opportunities.
- **Transparency & Trust:** We offer clear, transparent advice and ensure you understand your tax obligations and opportunities.
- **Ongoing Support:** Tax planning is not a one-time event. We provide continuous support to adapt to changes in your financial situation and tax laws.

---

## Our Process
1. **Initial Consultation**  
   We begin with a deep dive into your current financial situation, goals, and challenges.
2. **Custom Tax Strategy Development**  
   Based on your needs, we create a tax strategy that ensures you pay only what you owe—no more, no less.
3. **Implementation & Review**  
   We help implement the strategy and provide periodic reviews to adjust for any changes in your life or tax laws.
4. **Ongoing Monitoring & Adjustments**  
   Tax laws change, and so do your financial circumstances. We continually monitor and adjust your tax plan to keep it optimized.

---

## Let’s Start Planning
Don’t wait until tax season to think about your taxes. Contact us today to schedule a consultation and start planning for a more tax-efficient future. Whether you're an individual, family, or business owner, our expert team is here to help you achieve your financial goals.
      `,
      bgColor: "bg-green-600",
      textColor: "text-white",
    },
    {
      title: "Tax Return Services",
      shortDesc: "Comprehensive tax preparation and filing services",
      icon: "📝",
      content: `
# Tax Return Services

ATH Tax Services, Inc. offers comprehensive tax preparation and filing services designed to meet your unique financial needs. Whether you are an individual, small business, or corporation, we are here to help ensure your tax returns are accurate, timely, and compliant.

---

## Our Services

We provide a wide range of tax return services, including:

### 1. Individual Tax Returns
We help individuals file their personal tax returns, ensuring that you take advantage of all available deductions and credits. Our experts will guide you through the process with accuracy and efficiency.

### 2. Business Tax Returns
For small businesses and corporations, we offer tax return services to ensure compliance with federal, state, and local tax laws. We handle everything from sole proprietorships to LLCs and corporations.

### 3. Self-Employed Tax Returns
As a freelancer or contractor, we understand the complexities of self-employed tax returns. We will help you maximize your deductions and navigate the unique tax requirements for the self-employed.

### 4. Amended Tax Returns
If you need to correct a previously filed tax return, we offer amended return services to make necessary changes and ensure you are not paying more than you need to.

### 5. State & Federal Tax Filing
We file both state and federal returns, ensuring that all deadlines are met and you comply with tax regulations. We work with the IRS to resolve any issues that may arise with your tax filings.

---

## Why Choose ATH Tax Services, Inc.?
- **Expert Guidance:** Our team of experienced tax professionals is committed to providing clear, concise advice to help you make informed decisions.
- **Accurate Filings:** We ensure that your tax returns are filed correctly to minimize the risk of errors and penalties.
- **Maximized Refunds:** Our goal is to help you get the maximum refund possible by identifying all eligible deductions, credits, and exemptions.
- **Confidential & Secure:** We take your privacy seriously. All your personal and financial information is handled securely and confidentially.
- **Affordable Pricing:** We offer competitive pricing for tax preparation services, with no hidden fees.
      `,
      bgColor: "bg-white",
      textColor: "text-green-600",
    },
    {
      title: "Tax Consulting Services",
      shortDesc: "Expert tax consulting to navigate complex tax issues",
      icon: "📑",
      content: `
# Tax Consulting Services

At ATH Tax Services, Inc., we provide expert tax consulting services to help you navigate the complex world of taxation. Whether you need assistance with tax planning, compliance, or resolving tax issues, our team of professionals is here to guide you every step of the way.

---

## Our Consulting Services

We specialize in a variety of tax consulting services designed to optimize your tax strategy and ensure financial success:

### 1. Tax Planning & Strategy
We offer personalized tax planning services to help individuals and businesses minimize their tax liabilities. Our team will work with you to develop a tax strategy that aligns with your financial goals.

### 2. Tax Compliance & Regulations
Staying compliant with tax laws is essential for avoiding penalties. We help you stay up to date with federal, state, and local regulations, ensuring your tax filings and practices are fully compliant.

### 3. Business Tax Consulting
We provide businesses with strategic advice on tax issues such as deductions, credits, and corporate structures. Our expert consultants work with small businesses, corporations, and startups to optimize their tax positions.

### 4. IRS Representation & Audit Support
In case you face an IRS audit or need assistance with tax disputes, we offer IRS representation. We will guide you through the audit process and help resolve any issues with the IRS.

### 5. International Tax Consulting
For individuals or businesses dealing with international tax matters, we provide advice on foreign income, tax treaties, and other cross-border tax issues.

### 6. Estate & Trust Tax Planning
Plan for the future with estate and trust tax consulting. We help individuals and families navigate estate taxes, set up trusts, and ensure the smooth transfer of wealth in a tax-efficient manner.

### 7. Tax Credits & Deductions
Our consultants identify potential tax credits and deductions that you may be eligible for, helping you maximize your savings and lower your overall tax liability.
      `,
      bgColor: "bg-green-600",
      textColor: "text-white",
    },
    {
      title: "Tax Preparation Services",
      shortDesc: "Professional and reliable tax preparation for all needs",
      icon: "📂",
      content: `
# Tax Preparation Services

At ATH Tax Services, Inc., we provide professional and reliable tax preparation services for individuals and businesses. Our experienced team ensures that your tax filings are accurate, timely, and optimized for the best possible outcome. Whether you are filing a personal return or managing business taxes, we are here to help you every step of the way.

---

## Our Tax Preparation Services

### 1. Individual Tax Returns
We prepare and file personal tax returns for individuals, ensuring you take advantage of deductions, credits, and exemptions to minimize your tax liability. Whether you have a simple or complex tax situation, we provide expert services tailored to your needs.

### 2. Business Tax Returns
For small businesses, corporations, and LLCs, we offer comprehensive tax preparation services. We handle everything from annual tax filings to quarterly estimated taxes, ensuring that your business is fully compliant with tax laws.

### 3. Self-Employed & Freelancer Tax Returns
If you're a freelancer, contractor, or self-employed, we understand the unique tax challenges you face. We help you navigate tax deductions, self-employment taxes, and other considerations specific to your business structure.

### 4. Amended Tax Returns
If you need to amend a previously filed return, we offer services to correct errors and ensure that your tax filings are accurate. We assist you in filing amended returns (Form 1040-X) and resolving any issues with the IRS.

### 5. State & Federal Tax Filing
Whether you need to file your state, federal, or both returns, we are fully equipped to handle your needs. We ensure compliance with both federal and state tax laws, aiming for the best possible outcome in both jurisdictions.

### 6. Tax Filing for Expats
If you are living abroad, we offer specialized tax preparation services for U.S. citizens and residents living overseas, ensuring compliance with international tax laws and treaties.

### 7. Nonprofit Tax Returns
Nonprofit organizations have unique tax requirements. We help nonprofits file their tax returns, ensuring compliance with IRS regulations and assisting with annual filings like Form 990.

### 8. E-Filing Services
We offer e-filing services for fast and secure electronic submission of your tax return to the IRS and state tax authorities, ensuring quicker processing and quicker refunds.

---

## FAQ (Frequently Asked Questions)

### 1. What documents do I need to bring for tax preparation?
You will need to bring forms such as W-2s, 1099s, receipts for deductions, proof of investments, mortgage interest statements, and any other relevant financial documents.

### 2. How much does tax preparation cost?
Pricing varies based on the complexity of your tax return. We offer free consultations, and our services are competitively priced to suit your budget.

### 3. How long does it take to prepare and file my taxes?
The time it takes to complete your tax return depends on your situation. Typically, we aim to prepare your return within a few days of receiving all required documents.

### 4. When will I get my tax refund?
If you e-file and choose direct deposit, you can expect your refund within 7-21 days, depending on the IRS processing times.

### 5. Do you offer tax preparation for businesses?
Yes, we provide tax preparation services for businesses of all sizes, including LLCs, S-corps, C-corps, and partnerships.
      `,
      bgColor: "bg-white",
      textColor: "text-green-600",
    },
    {
      title: "ITIN Guidance Services",
      shortDesc: "Expert ITIN assistance for non-U.S. citizens and residents",
      icon: "🪪",
      content: `
# ITIN Guidance Services

At ATH Tax Services, Inc., we offer expert ITIN (Individual Taxpayer Identification Number) guidance and assistance to individuals who need to apply for an ITIN or resolve issues related to their existing ITIN. We help non-U.S. citizens and residents navigate the IRS process, ensuring that you meet all requirements for tax filing and compliance.

---

## Our ITIN Services

### 1. ITIN Application Assistance
If you are not eligible for a Social Security Number (SSN) but need to file taxes, we can help you apply for an ITIN. We guide you through the application process, ensuring that your form is correctly completed and submitted to the IRS.

### 2. ITIN Renewal
If your ITIN has expired or is about to expire, we help you renew it before you file your taxes. An expired ITIN can cause delays in processing your tax return and may affect your eligibility for certain tax credits, so timely renewal is essential.

### 3. ITIN Status Check
If you're unsure about the status of your ITIN or whether it is still active, we offer assistance to check its current status and advise you on the next steps if any issues arise.

### 4. Supporting Documentation Guidance
The ITIN application process requires supporting documentation, such as passports or visas. We provide guidance on the required documents, helping you gather and submit everything needed to avoid delays or rejections.

### 5. ITIN for Dependents
If you need to apply for an ITIN for a dependent, we help you with the application process, ensuring that you meet the IRS requirements to claim your dependents on your tax return.

### 6. ITIN for Non-resident Aliens
Non-resident aliens who need to file taxes in the U.S. but are not eligible for an SSN can apply for an ITIN. We assist you in applying for an ITIN and ensure that your tax returns are correctly filed.

### 7. Resolving ITIN Issues
If your ITIN application is rejected or you face issues with your ITIN, we provide troubleshooting services to help resolve problems efficiently and effectively.
      `,
      bgColor: "bg-white",
      textColor: "text-green-600",
    },
    {
      title: "Extension Filing Services",
      shortDesc: "Professional extension filing to meet tax deadlines",
      icon: "⏳",
      content: `
# Extension Filing Services

At ATH Tax Services, Inc., we offer professional extension filing services to ensure you have more time to file your tax returns. If you are unable to meet the tax filing deadline, we can help you apply for an extension with the IRS, giving you the additional time you need to gather your documents and file accurately. Our team makes the process simple and stress-free.

---

## Our Extension Filing Services

### 1. Individual Tax Extension Filing
If you need more time to file your personal tax return, we can help you file for an extension with the IRS. This allows you an additional 6 months to file your taxes, avoiding penalties for late filing.

### 2. Business Tax Extension Filing
For small businesses, corporations, and LLCs, we assist with filing an extension to ensure you have extra time to file your business tax returns. This extension applies to federal tax filing deadlines and gives you the flexibility to complete your return accurately.

### 3. State Tax Extension Filing
In addition to federal tax extensions, we also assist with state-level tax extensions. Each state has its own rules and deadlines, and we help you navigate both federal and state requirements to avoid any penalties or issues.

### 4. Self-Employed & Freelancer Extension Filing
If you are self-employed or a freelancer, we help you file for an extension, allowing you more time to gather your income and expense documentation. This is especially important for freelancers with complex tax filings.

### 5. Automatic Extension Filing
We can file an automatic extension for you with the IRS, which grants you an additional 6 months to file your tax return. **Note:** An extension to file is not an extension to pay; we will also guide you on estimated payments if necessary.

### 6. Extension Filing for Nonprofit Organizations
Nonprofits must also file their tax returns on time. We offer extension filing services for nonprofit organizations, giving them extra time to prepare and submit their Form 990 or other nonprofit returns.

### 7. Tax Advice During Extension Period
While your extension is in place, we offer ongoing advice and support to ensure you’re on track to file your taxes accurately when the extended deadline arrives.
      `,

      bgColor: "bg-green-600",
      textColor: "text-white",
    },
    {
      title: "Business Tax Returns Planning & Preparation",
      shortDesc: "Comprehensive business tax planning and filing services",
      icon: "🏢",
      content: `
# Business Tax Returns Planning & Preparation

At ATH Tax Services, Inc., we specialize in comprehensive business tax planning and preparation services. Whether you're a small business, corporation, LLC, or partnership, our experienced team is dedicated to providing tailored solutions that ensure your taxes are filed accurately and on time, while optimizing your tax strategy for maximum benefit. We handle everything from tax planning to the preparation and filing of your business tax returns, helping you stay compliant and minimize tax liabilities.

---

## Our Business Tax Services

### 1. Tax Planning for Businesses
Effective tax planning is crucial for business success. We work closely with your business to develop a strategic tax plan that maximizes deductions, credits, and tax-saving opportunities while ensuring full compliance with IRS regulations. Our services include forecasting tax liabilities, considering potential tax implications for future growth, and helping you make informed business decisions.

### 2. Corporate Tax Return Preparation
Whether your business is structured as a C-corporation or S-corporation, we provide expert tax preparation services to ensure that your corporate tax returns are filed accurately. We handle all necessary forms, including Forms 1120 and 1120S, and ensure that your business benefits from all available deductions and credits.

### 3. LLC & Partnership Tax Filing
For LLCs and partnerships, we prepare and file the required tax returns, including Forms 1065 and K-1. Our team ensures that your returns reflect the correct distribution of profits and losses among partners or members, and we handle all complexities of multi-member LLC taxation.

### 4. Small Business Tax Preparation
We specialize in helping small businesses, including sole proprietorships, freelancers, and self-employed individuals, prepare and file their tax returns. We focus on maximizing deductions for business expenses, including home office deductions, mileage, and other qualified costs.

### 5. Sales Tax Filing & Compliance
If your business is required to collect and remit sales tax, we assist with timely and accurate sales tax filings. We ensure that you're in compliance with state and local sales tax regulations, minimizing the risk of audits and penalties.

### 6. Payroll Tax Preparation & Reporting
For businesses with employees, we offer payroll tax preparation services, including filing quarterly payroll tax returns (Form 941), annual returns (Form 940), and state-specific payroll tax forms. We help ensure that your payroll taxes are filed on time and accurately.

### 7. Tax Filing for Nonprofit Organizations
Nonprofits have unique tax filing requirements. We assist nonprofit organizations with their tax filings, including preparing Form 990 and other required documentation, ensuring compliance with IRS rules for tax-exempt organizations.

### 8. Tax Reporting for International Businesses
If your business operates internationally or has foreign clients, we provide tax services to help navigate U.S. tax laws for foreign income, foreign tax credits, and compliance with international tax reporting requirements.
      `,

      bgColor: "bg-white",
      textColor: "text-green-600",
    },
    {
      title: "Payroll Processing Services",
      shortDesc: "Comprehensive payroll solutions for businesses",
      icon: "💰",
      content: `
# Payroll Processing Services

At ATH Tax Services, Inc., we provide comprehensive payroll processing services to help businesses streamline their payroll operations, ensure compliance with tax regulations, and reduce the time and effort spent on payroll administration. Whether you're a small business or a large organization, our team is dedicated to providing accurate, timely, and hassle-free payroll solutions tailored to your business needs.

---

## Our Payroll Processing Services

### 1. Full-Service Payroll Processing
We handle all aspects of your payroll, including calculating employee wages, deductions, tax withholdings, and overtime. Our services ensure that your employees are paid accurately and on time, and that your business complies with all federal, state, and local tax laws.

### 2. Payroll Tax Filing & Payment
We manage the filing of your payroll taxes, including federal, state, and local taxes, ensuring timely and accurate submission of forms such as Forms 941, 940, and state-specific payroll tax forms. We also handle tax payments to the IRS and state tax authorities, ensuring you avoid penalties and interest.

### 3. Employee Benefits Administration
We assist with managing employee benefits, including health insurance, retirement plans, and other deductions. Our payroll services help you ensure that benefit deductions are applied correctly and that your business stays compliant with employee benefit regulations.

### 4. Direct Deposit & Paycheck Printing
We offer direct deposit services for your employees, allowing them to receive their paychecks electronically. If you prefer paper checks, we can print and distribute paychecks, ensuring that all employees are paid promptly and securely.

### 5. Payroll Reports & Customization
Our payroll system generates detailed, customizable reports to help you track employee wages, tax deductions, and other payroll-related data. We provide comprehensive reports to help you manage your payroll budget and stay on top of business finances.

### 6. Year-End Payroll Tax Forms
At the end of the year, we prepare and file all necessary year-end payroll tax forms, including Forms W-2, W-3, and 1099, and provide these documents to employees and contractors. We ensure your year-end filings are accurate and submitted on time.

### 7. Garnishments & Wage Attachments
If any of your employees have wage garnishments (such as child support, tax levies, or other court-ordered deductions), we handle the appropriate deductions and ensure compliance with all legal requirements.

### 8. Compliance Assistance
Payroll laws and regulations change frequently. We stay up-to-date with the latest federal, state, and local payroll tax rules to ensure that your business remains compliant with all applicable laws and regulations.
      `,
      bgColor: "bg-green-600",
      textColor: "text-white",
    },
  ];

  // Function to handle clicking on a service card
  const openService = (index) => {
    setSelectedServiceIndex(index);
  };

  // Function to handle closing the modal
  const closeService = () => {
    setSelectedServiceIndex(null);
  };

  // Function to go to the previous service
  const goToPrevious = () => {
    if (selectedServiceIndex > 0) {
      setSelectedServiceIndex(selectedServiceIndex - 1);
    }
  };

  // Function to go to the next service
  const goToNext = () => {
    if (selectedServiceIndex < services.length - 1) {
      setSelectedServiceIndex(selectedServiceIndex + 1);
    }
  };

  // Handle Esc key to close the modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closeService();
      }
    };

    if (selectedServiceIndex !== null) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedServiceIndex]);

  const selectedService =
    selectedServiceIndex !== null ? services[selectedServiceIndex] : null;

  return (
    <div className="bg-white py-40">
      {/* Header Section */}
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="max-w-screen-sm sm:text-center sm:mx-auto">
          <a className="inline-block mb-5 rounded-full sm:mx-auto">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
              <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <h2 className="mb-4 font-sans text-5xl font-bold tracking-tight text-green-600 sm:text-6xl sm:leading-none">
            Our Services
          </h2>
          <hr className="w-full my-8 border-gray-300" />
        </div>
      </div>

      {/* Services Grid */}
      <div
        className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        // AOS fade-up for the entire grid container
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {services.map((service, index) => (
            <div
              key={service.title}
              // Each service card animation
              data-aos="zoom-in"
              data-aos-delay={index * 100} // Staggered delay for a cool effect
              data-aos-duration="600"
              className={`px-10 py-20 text-center transition duration-300 transform ${service.bgColor} rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl cursor-pointer`}
              onClick={() => openService(index)}
            >
              <div className="text-4xl mb-4">{service.icon}</div>
              <p className={`font-semibold ${service.textColor} text-2xl`}>
                {service.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedService && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div
            className="bg-white rounded-lg max-w-4xl w-full p-0 max-h-[90vh] flex flex-col relative"
            // Animate modal container
            data-aos="fade-in"
            data-aos-duration="500"
          >
            {/* Modal Header */}
            <div className="sticky top-0 bg-white z-10 flex flex-col sm:flex-row items-center justify-between p-4 border-b">
              {/* Left Side: Icon and Title */}
              <div className="flex items-center mb-4 sm:mb-0">
                <span className="text-4xl mr-4">{selectedService.icon}</span>
                <h2 className="text-2xl font-semibold text-green-600">
                  {selectedService.title}
                </h2>
              </div>

              {/* Right Side: Navigation and Close Button */}
              <div className="flex items-center space-x-2">
                <button
                  onClick={goToPrevious}
                  disabled={selectedServiceIndex === 0}
                  className={`px-4 py-2 rounded-lg text-sm sm:text-base ${
                    selectedServiceIndex === 0
                      ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                      : "bg-green-600 text-white hover:bg-green-700"
                  } transition duration-300`}
                >
                  Previous
                </button>
                <button
                  onClick={goToNext}
                  disabled={selectedServiceIndex === services.length - 1}
                  className={`px-4 py-2 rounded-lg text-sm sm:text-base ${
                    selectedServiceIndex === services.length - 1
                      ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                      : "bg-green-600 text-white hover:bg-green-700"
                  } transition duration-300`}
                >
                  Next
                </button>
                <button
                  onClick={closeService}
                  className="ml-4 text-gray-500 hover:text-gray-700 text-3xl sm:text-2xl font-bold"
                  aria-label="Close Modal"
                >
                  &times;
                </button>
              </div>
            </div>

            {/* Modal Content */}
            <div className="p-8 overflow-y-auto flex-1">
              <div className="prose max-w-none">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h1: ({ node, ...props }) => (
                      <h1
                        className="text-4xl font-bold my-6 text-green-600"
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <h2
                        className="text-3xl font-bold my-5 text-green-600"
                        {...props}
                      />
                    ),
                    h3: ({ node, ...props }) => (
                      <h3 className="text-2xl font-bold my-4 " {...props} />
                    ),
                    p: ({ node, ...props }) => (
                      <p className="mb-4" {...props} />
                    ),
                    li: ({ node, ...props }) => (
                      <li className="mb-2" {...props} />
                    ),
                    a: ({ node, ...props }) => (
                      <a className="text-green-600 hover:underline" {...props} />
                    ),
                    strong: ({ node, ...props }) => (
                      <strong className="font-semibold" {...props} />
                    ),
                  }}
                >
                  {selectedService.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceDetails;
