import React, { useEffect, useState } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { auth, storage } from "./firebase";
import { Download, FileText, Loader, AlertCircle } from "lucide-react";
import HorizontalBar from "./Horizontal";

const Final = () => {
  const [finalItems, setFinalItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchFinalDocuments = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          const storageRef = ref(storage, `user-uploads/${user.email}/`);
          const result = await listAll(storageRef);

          const finals = await Promise.all(
            result.items
              .filter((item) => item.name.startsWith("final-"))
              .map(async (item) => {
                const url = await getDownloadURL(item);
                return {
                  name: item.name.replace("final-", ""),
                  originalName: item.name,
                  url,
                  date: new Date().toLocaleDateString(), // You might want to get this from metadata
                };
              })
          );

          setFinalItems(finals);
        } catch (error) {
          console.error("Error fetching final files:", error);
          setFinalItems([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchFinalDocuments();
  }, []);

  const filteredItems = finalItems.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen py-28 bg-gradient-to-b ">
      <div className="py-8 px-4 lg:px-8">
        <HorizontalBar />
        
        {/* Header Section */}
        <div className="max-w-7xl mx-auto mt-8 text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Final Documents</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Access and download your completed tax documents securely from one place
          </p>
        </div>

        {/* Search and Stats */}
        <div className="max-w-7xl mx-auto mt-8 flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="w-full md:w-64">
            <div className="relative">
              <input
                type="text"
                placeholder="Search documents..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
              <span className="absolute right-3 top-2.5 text-gray-400">
                <FileText size={20} />
              </span>
            </div>
          </div>
          <div className="text-gray-600">
            Total Documents: {finalItems.length}
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto mt-8">
          {loading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader className="w-10 h-10 text-green-500 animate-spin" />
              <p className="mt-4 text-gray-600">Loading your documents...</p>
            </div>
          ) : finalItems.length > 0 ? (
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Document Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date Added
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredItems.map((item, index) => (
                      <tr 
                        key={index}
                        className="hover:bg-white transition-colors duration-200"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FileText className="flex-shrink-0 h-5 w-5 text-gray-400 mr-3" />
                            <div className="text-sm font-medium text-gray-900">
                              {item.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center">
                          <button
                            onClick={() => window.open(item.url, "_blank")}
                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
                          >
                            <Download className="w-4 h-4 mr-2" />
                            Download
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="text-center py-12 bg-white rounded-lg shadow-lg">
              <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-4 text-lg font-medium text-gray-900">No Documents Found</h3>
              <p className="mt-2 text-gray-500">
                Your final documents will appear here once they are processed and ready.
              </p>
            </div>
          )}
        </div>

        {/* Footer Info */}
        <div className="max-w-7xl mx-auto mt-8 p-4 bg-blue-50 rounded-lg">
          <div className="flex items-start space-x-3">
            <AlertCircle className="w-5 h-5 text-blue-500 mt-0.5" />
            <div>
              <h4 className="text-sm font-medium text-blue-800">Important Note</h4>
              <p className="mt-1 text-sm text-blue-600">
                Please ensure to save your documents in a secure location after downloading. 
                These documents contain sensitive information and should be handled carefully.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Final;