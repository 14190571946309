// EditProfileModal.js
import React, { useState } from "react";
import { db, auth } from "./firebase";
import { doc, setDoc } from "firebase/firestore";
import { FaTimes } from "react-icons/fa";

const EditProfileModal = ({ userData, setUserData, setIsEditModalOpen }) => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState("");

  // Handle input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Close the modal
  const handleClose = () => {
    setIsEditModalOpen(false);
    setError("");
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!userData.firstName || !userData.lastName || !userData.phone) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      setUpdating(true);
      const user = auth.currentUser;
      if (!user) {
        setError("User not authenticated");
        return;
      }

      // Reference to the user's document in Firestore
      const userDocRef = doc(db, "users", user.email);

      // Update user data in Firestore
      await setDoc(
        userDocRef,
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          referralEmail: userData.referralEmail,
        },
        { merge: true } // Merge with existing data
      );

      alert("Profile updated successfully!");
      setIsEditModalOpen(false); // Close the modal
    } catch (err) {
      console.error("Error updating profile:", err);
      setError("Failed to update profile. Please try again.");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        >
          <FaTimes size={20} />
        </button>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Edit Profile</h2>

        {/* Display error message if any */}
        {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* First Name */}
          <div>
            <label
              htmlFor="firstName"
              className="block text-gray-700 font-medium mb-1"
            >
              First Name<span className="text-red-500">*</span>
            </label>
            <input
              id="firstName"
              type="text"
              value={userData.firstName}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
          </div>

          {/* Last Name */}
          <div>
            <label
              htmlFor="lastName"
              className="block text-gray-700 font-medium mb-1"
            >
              Last Name<span className="text-red-500">*</span>
            </label>
            <input
              id="lastName"
              type="text"
              value={userData.lastName}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
          </div>

          {/* Phone */}
          <div>
            <label
              htmlFor="phone"
              className="block text-gray-700 font-medium mb-1"
            >
              Phone<span className="text-red-500">*</span>
            </label>
            <input
              id="phone"
              type="tel"
              value={userData.phone}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
          </div>

          {/* Referral Email */}
          <div>
            <label
              htmlFor="referralEmail"
              className="block text-gray-700 font-medium mb-1"
            >
              Referral Email
            </label>
            <input
              id="referralEmail"
              type="email"
              value={userData.referralEmail}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              disabled={updating}
              className={`w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ${
                updating ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {updating ? "Updating..." : "Update Profile"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfileModal;
