import React, { useEffect } from 'react';
import { CheckCircle, Clock, ArrowLeft, Mail } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const Submitted = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize AOS
    if (typeof window !== 'undefined') {
      const AOS = require('aos');
      AOS.init({
        duration: 1000,
        once: true,
      });
    }
  }, []);

  return (
    <div className="min-h-screen py-28 bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center px-4">
      <div className="w-full max-w-4xl" data-aos="fade-up">
        <div className="bg-white shadow-xl rounded-2xl overflow-hidden">
          <div className="p-8">
            {/* Success Icon */}
            <div className="flex justify-center mb-8" data-aos="zoom-in" data-aos-delay="200">
              <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center">
                <CheckCircle className="w-12 h-12 text-green-600" />
              </div>
            </div>

            {/* Main Content */}
            <div className="text-center space-y-6" data-aos="fade-up" data-aos-delay="400">
              <h2 className="text-4xl font-bold text-gray-900">
                Submission Successful!
              </h2>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                Thank you for trusting us with your tax documents.
                Our expert team will review your submission shortly.
              </p>
            </div>

            {/* Timeline */}
            <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8" data-aos="fade-up" data-aos-delay="600">
              <div className="text-center space-y-3">
                <div className="flex justify-center">
                  <CheckCircle className="w-8 h-8 text-green-600" />
                </div>
                <h3 className="font-semibold text-gray-900">Documents Received</h3>
                <p className="text-sm text-gray-500">Your files have been successfully uploaded</p>
              </div>

              <div className="text-center space-y-3">
                <div className="flex justify-center">
                  <Clock className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="font-semibold text-gray-900">Under Review</h3>
                <p className="text-sm text-gray-500">24-48 hours processing time</p>
              </div>

              <div className="text-center space-y-3">
                <div className="flex justify-center">
                  <Mail className="w-8 h-8 text-purple-600" />
                </div>
                <h3 className="font-semibold text-gray-900">Email Notification</h3>
                <p className="text-sm text-gray-500">We'll email you once processing is complete</p>
              </div>
            </div>

            {/* Additional Information */}
            <div className="mt-12 bg-white rounded-lg p-6" data-aos="fade-up" data-aos-delay="800">
              <h4 className="font-semibold text-gray-900 mb-3">What happens next?</h4>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-green-600 rounded-full mr-2"></div>
                  Our tax experts will review your documents
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-green-600 rounded-full mr-2"></div>
                  We'll contact you if any additional information is needed
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-green-600 rounded-full mr-2"></div>
                  You'll receive a detailed analysis of your tax situation
                </li>
              </ul>
            </div>

            {/* Actions */}
            <div className="mt-12 flex flex-col sm:flex-row justify-center gap-4" data-aos="fade-up" data-aos-delay="1000">
              <button
                onClick={() => navigate('/dashboard')}
                className="flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-300"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Back to Dashboard
              </button>
           
            </div>

            {/* Footer */}
            <div className="mt-12 text-center" data-aos="fade-up" data-aos-delay="1200">
              <p className="text-sm text-gray-500">
                Get Your Max Refund with 100% Accuracy Guaranteed
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submitted;