// HorizontalBar.js
import React from "react";
import { Link } from "react-router-dom";
import {
  FaFileDownload,
  FaUpload,
  FaFileAlt,
  FaFileInvoiceDollar,
} from "react-icons/fa";

const HorizontalBar = () => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8">
      {/* Header Section */}
      <div className="max-w-screen-sm sm:text-center sm:mx-auto mb-6">
        <a
          href="#"
          aria-label="Dashboard Icon"
          className="inline-block mb-4 rounded-full"
        >
          <div className="flex items-center justify-center w-16 h-16 rounded-full bg-green-100 mx-auto">
            <svg
              className="w-12 h-12 text-green-500"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
        </a>
        <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
          Dashboard
        </h2>
        <hr className="w-full my-4 border-gray-300" />
      </div>

      {/* Navigation Buttons */}
      <div className="flex flex-col sm:flex-row flex-wrap justify-center items-center gap-4">
        <Link
          to="/taxinfo"
          className="flex items-center justify-center w-full sm:w-auto bg-green-600 text-white px-6 py-3 rounded-lg shadow hover:bg-green-700 transition duration-300 ease-in-out transform hover:scale-105"
        >
          <FaFileDownload className="mr-2" />
          Download Tax Information
        </Link>
        <Link
          to="/my-account"
          className="flex items-center justify-center w-full sm:w-auto bg-red-600 text-white px-6 py-3 rounded-lg shadow hover:bg-red-700 transition duration-300 ease-in-out transform hover:scale-105"
        >
          <FaUpload className="mr-2" />
          Upload Documents
        </Link>
        <Link
          to="/draft"
          className="flex items-center justify-center w-full sm:w-auto bg-blue-500 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
        >
          <FaFileAlt className="mr-2" />
          Download Tax Draft
        </Link>
        <Link
          to="/final"
          className="flex items-center justify-center w-full sm:w-auto bg-indigo-500 text-white px-6 py-3 rounded-lg shadow hover:bg-indigo-600 transition duration-300 ease-in-out transform hover:scale-105"
        >
          <FaFileInvoiceDollar className="mr-2" />
          Download Final Tax Documents
        </Link>
      </div>
    </div>
  );
};

export default HorizontalBar;
