// src/components/About.jsx
import React, { useEffect } from "react";
/* 1. Import AOS */
import AOS from "aos";
import "aos/dist/aos.css";

/* 2. Import Images */
import about1 from "../pages/aboutus.jpg"; // Dummy Image 1
import about2 from "../pages/back.jpg"; // Dummy Image 2

/* 3. Import ImageCard Component */
import ImageCard from "./ImageCard";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Initialize AOS
    AOS.init({
      duration: 800, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });
    AOS.refresh();
  }, []);

  /* 4. Content Data */
  const contentBlocks = [
    {
      id: 1,
      title: "Our Mission",
      text: `ATH Tax Services, Inc. (AthTax.com) is dedicated to providing comprehensive tax consulting services that empower individuals and businesses to achieve financial clarity and compliance. Since 2015, we have been committed to simplifying complex tax processes, ensuring our clients can focus on what they do best.`,
      image: about1,
      imageFirst: false, // Image on the right
    },
    {
      id: 2,
      title: "Our Expertise",
      text: `With a team of over 450 expert accountants, ATH Tax Services offers a wide range of services including tax return preparation, tax planning, compliance, accounting, bookkeeping, business advisory, business incorporation, and payroll management. Our extensive experience ensures that we can handle diverse financial needs efficiently.`,
      image: about2,
      imageFirst: true, // Image on the left
    },
    // Add more content blocks as needed
  ];

  return (
    <div className="bg-white py-20">
      {/* Heading Section */}
      <div
        className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
        data-aos="fade-up"
        data-aos-duration="600"
      >
        <div className="max-w-screen-sm sm:text-center sm:mx-auto">
          <div className="inline-block mb-5 rounded-full">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-100 mx-auto">
              <svg
                className="w-12 h-12 text-green-600"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </div>
          <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
            What We Do
          </h2>
          <hr className="w-full my-6 border-gray-300" />
        </div>
      </div>

      {/* Main Content */}
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        {contentBlocks.map((block, index) => (
          <div
            key={block.id}
            className={`flex flex-col ${
              block.imageFirst ? "lg:flex-row-reverse" : "lg:flex-row"
            } items-center mb-16`}
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay={index * 100}
          >
            {/* Text Content */}
            <div className="mx-8 w-full lg:w-1/2">
              <h3 className="text-2xl font-semibold text-green-600 mb-4">
                {block.title}
              </h3>
              <p className="text-sm text-gray-700 md:text-base leading-relaxed">
                {block.text}
              </p>
            </div>

            {/* Image */}
            <div className="w-full lg:w-1/2 mt-6 lg:mt-0 lg:ml-8">
              <ImageCard
                src={block.image}
                alt={block.title}
                aos="fade-up"
                delay={200 + index * 100}
              />
            </div>
          </div>
        ))}

        {/* Stats Section */}
        <div
          className="mt-16"
          data-aos="fade-up"
          data-aos-duration="600"
        >
          <div className="grid gap-8 row-gap-8 sm:grid-cols-3">
            {/* 1. Years in the Business */}
            <div className="text-center p-6 bg-green-50 rounded shadow hover:shadow-lg transition duration-300 hover:scale-105">
              <h6 className="text-4xl font-bold text-green-600">8</h6>
              <p className="font-semibold mt-2">Years In The Business</p>
            </div>

            {/* 2. Happy Clients */}
            <div className="text-center p-6 bg-green-50 rounded shadow hover:shadow-lg transition duration-300 hover:scale-105">
              <h6 className="text-4xl font-bold text-green-600">300,000+</h6>
              <p className="font-semibold mt-2">Happy Clients</p>
            </div>

            {/* 3. Expert Accountants */}
            <div className="text-center p-6 bg-green-50 rounded shadow hover:shadow-lg transition duration-300 hover:scale-105">
              <h6 className="text-4xl font-bold text-green-600">450</h6>
              <p className="font-semibold mt-2">Expert Accountants</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
