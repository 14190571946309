// src/components/ImageCard.jsx
import React from "react";

const ImageCard = ({ src, alt, aos, delay }) => (
  <div
    className="relative overflow-hidden rounded-lg shadow-lg"
    data-aos={aos}
    data-aos-delay={delay}
    data-aos-duration="600"
  >
    <img
      className="object-cover w-full h-64 sm:h-80 md:h-96 transition-transform duration-300 transform hover:scale-105"
      src={src}
      alt={alt}
      loading="lazy"
    />
    <div className="absolute inset-0 bg-green-600 bg-opacity-25 opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
  </div>
);

export default ImageCard;
