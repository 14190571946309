import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import toast, { Toaster } from "react-hot-toast";
import DOMPurify from "dompurify";

// Optional: Import Heroicons for better UI (Install via npm if not already)
import { PencilIcon, TrashIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

export const Blog = () => {
  // State Variables
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [popupContent, setPopupContent] = useState(null);
  const [loading, setLoading] = useState(true);

  // Filter and Sort States
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedTag, setSelectedTag] = useState("All");
  const [availableYears, setAvailableYears] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  // Fetch Blogs from Firestore
  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "blog-posts"),
          where("published", "==", true)
        );
        const snapshot = await getDocs(q);
        const blogList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Ensure createdAt is a string; adjust if using Firestore Timestamps
          createdAt: doc.data().createdAt || new Date().toISOString(),
        }));
        setBlogs(blogList);
        setFilteredBlogs(blogList);
        extractFilterOptions(blogList);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        toast.error("Failed to fetch blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  // Extract Unique Years and Tags for Filters
  const extractFilterOptions = (blogsList) => {
    const years = new Set();
    const tags = new Set();

    blogsList.forEach((blog) => {
      const year = new Date(blog.createdAt).getFullYear();
      years.add(year);

      if (blog.tags && Array.isArray(blog.tags)) {
        blog.tags.forEach((tag) => tags.add(tag));
      }
    });

    setAvailableYears(["All", ...Array.from(years).sort((a, b) => b - a)]);
    setAvailableTags(["All", ...Array.from(tags).sort()]);
  };

  // Handle Filtering and Sorting
  useEffect(() => {
    let updatedBlogs = [...blogs];

    // Search by Title
    if (searchQuery.trim() !== "") {
      updatedBlogs = updatedBlogs.filter((blog) =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Filter by Year
    if (selectedYear !== "All") {
      updatedBlogs = updatedBlogs.filter(
        (blog) => new Date(blog.createdAt).getFullYear().toString() === selectedYear
      );
    }

    // Filter by Tag
    if (selectedTag !== "All") {
      updatedBlogs = updatedBlogs.filter(
        (blog) => blog.tags && blog.tags.includes(selectedTag)
      );
    }

    setFilteredBlogs(updatedBlogs);
  }, [searchQuery, selectedYear, selectedTag, blogs]);

  // Handle "Learn More" Click
  const handleLearnMoreClick = (blog) => {
    setPopupContent(blog);
  };

  // Handle Popup Close
  const handleClosePopup = () => {
    setPopupContent(null);
  };

  return (
    <div className="bg-white min-h-screen">
      {/* Toaster for Notifications */}
      <Toaster position="top-right" />

      {/* Blog Section Header */}
      <div className="px-4 py-40 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="max-w-screen-md sm:text-center sm:mx-auto">
          <h2 className="mb-6 font-sans text-4xl font-extrabold tracking-tight text-green-700 sm:text-5xl sm:leading-none">
            Blogs
          </h2>
          <p className="text-gray-600 text-lg">
            Explore our latest articles, insights, and stories.
          </p>
          <hr className="w-full my-8 border-gray-300" />
        </div>

        {/* Filters */}
        <div className="mb-8 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          {/* Search Bar */}
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by title..."
            className="w-full md:w-1/3 px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 transition duration-200"
          />

          {/* Sort by Year */}
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="w-full md:w-1/4 px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 transition duration-200"
          >
            {availableYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          {/* Sort by Tag */}
          <select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            className="w-full md:w-1/4 px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 transition duration-200"
          >
            {availableTags.map((tag) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </select>
        </div>

        {/* Blog Cards */}
        {loading ? (
          <div className="text-center text-gray-600">Loading blogs...</div>
        ) : filteredBlogs.length === 0 ? (
          <div className="text-center text-gray-600">No blogs available</div>
        ) : (
          <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
            {filteredBlogs.map((blog) => (
              <div
                key={blog.id}
                className="overflow-hidden transition-shadow duration-300 bg-white rounded-lg shadow-lg hover:shadow-2xl"
              >
                {blog.imageUrl && (
                  <img
                    src={blog.imageUrl}
                    alt={blog.title}
                    className="object-cover w-full h-48 border-b border-gray-300"
                  />
                )}
                <div className="p-6">
                  <p className="mb-3 text-xs font-semibold tracking-wide text-green-600 uppercase">
                    Published
                  </p>
                  <h3
                    className="mb-3 text-2xl font-bold leading-5 text-gray-800 cursor-pointer hover:text-green-600 transition"
                    onClick={() => handleLearnMoreClick(blog)}
                  >
                    {blog.title}
                  </h3>
                  <div
                    className="text-gray-700 mb-4"
                    dangerouslySetInnerHTML={{
                      __html:
                        blog.content.length > 100
                          ? DOMPurify.sanitize(blog.content.substring(0, 100)) + "..."
                          : DOMPurify.sanitize(blog.content),
                    }}
                  ></div>

                  {/* Tags */}
                  {blog.tags && blog.tags.length > 0 && (
                    <div className="flex flex-wrap gap-2 mb-4">
                      {blog.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-blue-100 text-blue-800 text-sm rounded-full"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}

                  <span
                    className="cursor-pointer text-blue-500 hover:underline"
                    onClick={() => handleLearnMoreClick(blog)}
                  >
                    Learn more
                  </span>
                  <p className="text-sm text-gray-500 mt-2">
                    Created At: {new Date(blog.createdAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Popup Modal */}
      {popupContent && (
        <div className="fixed z-50 inset-0 bg-black bg-opacity-60 flex items-center justify-center overflow-auto fade-in">
          <div className="bg-white max-w-screen-md w-11/12 lg:w-3/4 p-8 relative rounded-lg shadow-lg">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-gray-700 hover:text-red-600 transition transform hover:scale-110"
              onClick={handleClosePopup}
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            {/* Popup Content */}
            <h3 className="text-3xl font-bold text-green-700 mb-6">
              {popupContent.title}
            </h3>
            {popupContent.imageUrl && (
              <img
                src={popupContent.imageUrl}
                alt={popupContent.title}
                className="w-full h-64 object-cover rounded-lg mb-6"
              />
            )}
            {/* Tags */}
            {popupContent.tags && popupContent.tags.length > 0 && (
              <div className="flex flex-wrap gap-2 mb-4">
                {popupContent.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-100 text-blue-800 text-sm rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            )}
            <div
              className="text-gray-700 leading-relaxed space-y-4 max-h-[50vh] overflow-y-auto"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(popupContent.content) }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};
