import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { db } from "./firebase"; // Ensure you import Firebase configuration

Modal.setAppElement("#root"); // Set the app root for accessibility

export default function Appointment() {
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Save additional user details to Firestore
      await db.collection("Appointment").doc(email).set({
        firstName,
        phone,
        email,
      });

      toast.success("Appointment booked successfully!");
      setIsModalOpen(true); // Open the modal after successful booking
    } catch (error) {
      setError(error.message);
      toast.error(`Booking error: ${error.message}`);
    }
  };

  return (
    <div className="bg-white pt-40">
      <div className="relative">
        <div className="relative bg-opacity-75 bg-deep-purple-accent-700">
          <svg
            className="absolute inset-x-0 bottom-0 text-green-600"
            viewBox="0 0 1160 163"
          >
            <path
              fill="currentColor"
              d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"
            />
          </svg>
          <div className="relative px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between xl:flex-row">
              <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
                <h2 className="text-green-600 max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none">
                  File Your Taxes With
                  <br className="hidden md:block" />
                  ATH Services
                </h2>
                <div className="space-y-4">
                  <p className="text-lg font-bold">Get instant refund</p>
                  <p className="text-lg font-bold">Free tax estimate</p>
                  <p className="text-lg font-bold">Money back guarantee</p>
                </div>
              </div>
              <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
                <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
                  <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl text-green-600">
                    Book An Appointment
                  </h3>
                  <form onSubmit={handleRegister}>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="firstName"
                        className="inline-block mb-1 font-medium"
                      >
                        Full Name
                      </label>
                      <input
                        placeholder="John"
                        required
                        type="text"
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:shadow-outline"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="phone"
                        className="inline-block mb-1 font-medium"
                      >
                        Phone Number
                      </label>
                      <input
                        placeholder="123-456-7890"
                        required
                        type="tel"
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:shadow-outline"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="email"
                        className="inline-block mb-1 font-medium"
                      >
                        Email
                      </label>
                      <input
                        placeholder="john.doe@example.org"
                        required
                        type="email"
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:shadow-outline"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mt-4 mb-2 sm:mb-4">
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide bg-green-600 text-white transition duration-200 rounded shadow-md hover:bg-green-700 focus:shadow-outline focus:outline-none"
                      >
                        Book
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Google Calendar Booking */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Google Calendar Booking"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
            },
          }}
        >
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-red-500 text-white px-4 py-2 rounded mb-4"
          >
            Close
          </button>
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2Uj2tltBrCEB2tjxPhSWvDV0QAhinkKdAZS-CzibIfdlWy2Eq4HoZOZWYsqYQGqw4gNIADL61G?gv=true"
            style={{ border: 0, width: "100%", height: "100%" }}
            frameBorder="0"
            title="Google Calendar"
          ></iframe>
        </Modal>

        {/* Toast container */}
        <ToastContainer />
      </div>
    </div>
  );
}
