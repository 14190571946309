import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../pages/firebase"; // Import the auth object from your firebase.js file
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa"; // Install via npm: `npm install react-icons`
import logo from "./tax.jpg";

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null); // User state
  let navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    auth.signOut();
    navigate("/");
    setIsMenuOpen(false); // Close the menu after logout
  };

  return (
    <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl font-medium md:px-24 lg:px-8 bg-white rounded-md">
      <div className="relative flex items-center justify-between">
        <Link
          to="/"
          aria-label="Company"
          title="Company"
          className="inline-flex items-center"
        >
          <svg
            className="w-8"
            viewBox="0 0 24 24"
            strokeLinejoin="round"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            stroke="currentColor"
            fill="none"
          >
            <rect
              className="text-green-600"
              x="3"
              y="1"
              width="7"
              height="12"
            />
            <rect x="3" y="17" width="7" height="6" />
            <rect x="14" y="1" width="7" height="6" />
            <rect
              className="text-green-600"
              x="14"
              y="11"
              width="7"
              height="12"
            />
          </svg>
          <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
            ATH Tax Services
          </span>
        </Link>
        <ul className="flex items-center hidden space-x-8 lg:flex">
          <li>
            <Link
              to="/"
              aria-label="Home"
              title="Home"
              className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/service"
              aria-label="Services"
              title="Services"
              className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/back"
              aria-label="Money Back Guarantee"
              title="Money Back Guarantee"
              className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Money Back Guarantee
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              aria-label="Blogs"
              title="Blogs"
              className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Blogs
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              aria-label="About Us"
              title="About Us"
              className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              About us
            </Link>
          </li>
          <li>
            <img className="h-12" src={logo} alt="Logo" />
          </li>
          {user ? (
            <>
              <li>
                <button
                  onClick={handleLogout}
                  className="inline-flex items-center justify-center h-12 px-6 bg-red-600 text-white transition duration-200 rounded shadow-md hover:bg-red-700 focus:shadow-outline focus:outline-none"
                >
                  Logout
                </button>
              </li>
              <li>
                <Link
                  to="/my-account"
                  className="inline-flex items-center justify-center h-12 px-6 bg-green-600 text-white transition duration-200 rounded shadow-md hover:bg-green-700 focus:shadow-outline focus:outline-none"
                  aria-label="My Account"
                  title="My Account"
                >
                  My Account
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to="/login"
                  className="inline-flex items-center justify-center h-12 px-6 bg-green-600 text-white transition duration-200 rounded shadow-md hover:bg-green-700 focus:shadow-outline focus:outline-none"
                  aria-label="Login"
                  title="Login"
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  className="inline-flex items-center justify-center h-12 px-6 bg-green-600 text-white transition duration-200 rounded shadow-md hover:bg-green-700 focus:shadow-outline focus:outline-none"
                  aria-label="Sign up"
                  title="Sign up"
                >
                  Sign up
                </Link>
              </li>
            </>
          )}
        </ul>
        <div className="lg:hidden">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={handleMenuToggle}
          >
            <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full">
              <div className="p-5 bg-white border rounded shadow-sm">
                <nav>
                  <ul className="space-y-4">
                    <li>
                      <Link
                        to="/"
                        aria-label="Home"
                        title="Home"
                        className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                        onClick={() => setIsMenuOpen(false)} // Close menu on link click
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service"
                        aria-label="Services"
                        title="Services"
                        className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/back"
                        aria-label="Money Back Guarantee"
                        title="Money Back Guarantee"
                        className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Money Back Guarantee
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        aria-label="Blogs"
                        title="Blogs"
                        className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        aria-label="About Us"
                        title="About Us"
                        className="tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        About us
                      </Link>
                    </li>
                    {/* Authentication Links */}
                    {user ? (
                      <>
                        <li>
                          <button
                            onClick={handleLogout}
                            className="w-full text-left tracking-wide text-red-600 font-medium transition-colors duration-200 hover:text-red-700"
                          >
                            Logout
                          </button>
                        </li>
                        <li>
                          <Link
                            to="/my-account"
                            className="tracking-wide text-green-600 font-medium transition-colors duration-200 hover:text-green-700"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            My Account
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link
                            to="/login"
                            className="tracking-wide text-green-600 font-medium transition-colors duration-200 hover:text-green-700"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/register"
                            className="tracking-wide text-green-600 font-medium transition-colors duration-200 hover:text-green-700"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Sign up
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* WhatsApp Chat Support */}
      <div className="fixed bottom-4 right-4 group">
        <a
          href="https://wa.me/+18046657826?text=Hello%2C%20I%20need%20help%20with%20tax%20filing"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg flex items-center justify-center relative"
        >
          <FaWhatsapp size={32} />
          <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block bg-black text-white text-sm py-1 px-2 rounded whitespace-nowrap">
            Contact Us
          </span>
        </a>
      </div>
    </div>
  );
}
